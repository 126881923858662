/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');*/

/* base styles */
* {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: #333;
}

body {
  font-size: 15px !important;
}

.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 1000px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.navbar h1 {
  color: #31356d;
}
.navbar .links {
  margin-right: auto;
}
.navbar .links a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #31356d;
}
.content {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
}



body {
  padding-left: 270px;
    padding-top: 70px;
}

.app .content {
  margin-top: 0;  
}

.navbar {
    padding: 0 !important;
    border: none;
}

.navbar h1 {
    position: fixed;
    display: block;
    padding: 15px !important;
    width: 100%;
    margin: 0;
    left: 0;
    text-align: center;
    background: #fff;
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    z-index: 500;
    font-size: 24px;
    top: 0; 
}

.navbar .links {
  position: fixed;
  left: 0;
  padding: 90px 0 40px 0;
  height:  100%;
  top: 0;
  width: 250px; 
  background: #f5f9fe;
  z-index: 100;
  overflow-y: auto;
}

.navbar .links a {
    display: block;
    margin: 0;
    padding: 10px 20px;
    line-height: 1.2;
    color: #333;
    display: flex;
    min-height: 45px;
    align-items: center;
    font-size: 14px;
}

.navbar .links a:hover {
    color: #0d6efd; 
}

.navbar .links a.active {
  /*background: #0d6efd21;*/
  background-color: rgba(24, 118, 189,.1);
  color: #1876BD;
}

.active-nav-link {
    background-color: rgba(24, 118, 189,.1);
    color: #1876BD;
}


/*.apexcharts-canvas,
.apexcharts-svg,
[type="boxPlot"],
.mixed-chart {
  min-height: 300px !important;
  height: 300px !important;
}*/


.mixed-chart.compact .apexcharts-toolbar {
  display: none;
}


.nav-tabs.restyle {
  padding: 5px 3px;
}
.nav-tabs.restyle .nav-item {
  border-radius: 5px;
  border: 1px solid transparent;
  color:  inherit;
}

.nav-tabs.restyle .nav-item:hover {
  color: #1876BD;
}

.nav-tabs.restyle .nav-item.active {
  border-color: #1876BD;
  background-color: rgba(24, 118, 189,.8);
  color: #ffffff;
}


.filtersBox {
  padding: 10px 20px;
}

.filtersBox select {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
}

.filtersBox select[disabled] {
  opacity: .5;
}